<template>
  <v-row>
    <v-col cols="12" sm="12" md="12">
      <v-container fluid>
        <v-card raised class="mx-auto pa-5 card-custom-rounded-10 mb-7">
          <v-list-item class="mb-5">
            <v-list-item-content>
              <v-list-item-title class="headline mb-1"
                >Profile</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-row>
            <v-col cols="3">
              <v-card-actions class="justify-center ma-0">
                <v-img :src="profile.photo" contain height="250" />
              </v-card-actions>
            </v-col>
            <v-col cols="9">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td style="width: 20%">
                        {{ profile.role === "USER" ? "NIP" : "Username" }}
                      </td>
                      <td>{{ profile.username }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%">Role</td>
                      <td>{{ profile.role_name }}</td>
                    </tr>
                    <tr>
                      <td style="width: 20%">Name</td>
                      <td>{{ profile.name }}</td>
                    </tr>
                    <template v-if="profile.role === 'USER'">
                      <tr>
                        <td style="width: 20%">Unit Utama</td>
                        <td>{{ profile.nama_unit_utama }}</td>
                      </tr>
                      <tr>
                        <td style="width: 20%">Unit Kerja</td>
                        <td>
                          <p class="mb-0">{{ profile.nama_unit_kerja_2 }}</p>
                          <p class="mb-0">{{ profile.nama_unit_kerja_3 }}</p>
                          <p class="mb-0">{{ profile.nama_unit_kerja_4 }}</p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row class="mt-10 ml-1">
            <v-btn
              @click="$router.push({ name: 'profile.change.password' })"
              color="primary"
              class="px-10 mr-5"
            >
              <v-icon left>lock</v-icon>
              Change Password
            </v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import AuthService from "@/services/resources/auth.service";
import { mapAuthRootField } from "@/store/helpers";

export default {
  data() {
    return {
      showRoles: false,
      showBranches: false,
      loading: false
    };
  },
  computed: {
    ...mapAuthRootField({
      profile: "profile"
    })
  },
  methods: {
    // Service
    async getCurrentUser() {
      try {
        this.loading = true;
        await AuthService.getProfile({ platform: process.env.VUE_APP_MODE })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.profile = this.$_.merge(this.profile, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCurrentUser();
    });
  }
};
</script>
